<template lang="pug">
.social-media__links
  a(href="https://vk.com/nekidaem_ru" target="_blank").social-media__item
      img(
      src="~/assets/images/svg/icons/vk.svg"
      width="24"
      height="24"
      alt="vk")
  a(href="https://t.me/nekidaem_official" target="_blank").social-media__item
    img(
      src="~/assets/images/svg/icons/telegram.svg"
      width="24"
      height="24"
      alt="telegram")
</template>

<script>
export default {
  mounted() {
    const lazyImages = document.querySelectorAll('.lazy__image')
    this.$setLazyLoading(lazyImages, null)
  },
}
</script>

<style lang="scss" scoped>
.social-media {
  &__links {
    max-width: 1400px;
    width: 100%;
    position: relative;
    display: flex;
    padding: 0;

    @include md-desktop {
      padding: 0 20px;
    }
  }

  &__item {
    margin-right: 30px;
  }
}
</style>
